<template>
	<div class="transfer">
		<w-navTab titleText="爱心传递"></w-navTab>
		<vant-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="head-top">
				<img src="@/assets/img/test254.png" alt="" />
			</div>
			<div class="nav">
				<w-tab :active="active" :data="loveData" @click="onTab"></w-tab>
			</div>
			<div class="middle">
				<div class="essay-list" v-for="item in transferList"
					@click="$router.push({ name: 'TransferDetails', params: { id: item.id } })">
					<div>
						<div class="title">{{ item.title }}</div>
						<div class="text">{{ item.intro }}</div>
						<div class="middle">
							<div class="p-line">
								<div class="p-done" :style="{
									width:
										progressWidth(
											item.donate_integral,
											item.need_integral
										) + '%'
								}"></div>
							</div>
						</div>
						<div class="essay-list-sort">
							<div>已筹/爱心积分</div>
							<div>目标/爱心积分</div>
						</div>
						<div class="essay-list-amount">
							<div>{{ item.donate_integral == null ? 0 : item.donate_integral }}</div>
							<div>{{ item.need_integral }}</div>
						</div>
					</div>
					<div class="footer">
						<div class="footer-left" v-if="item.donate_integral >= item.need_integral">爱心筹集已完成</div>
						<div class="footer-left" v-else>爱心筹集中</div>
						<div class="footer-right">
							<div class="footer-right-like">
								<div class="picture"><img src="@/assets/img/icon201.png" /></div>
								<span>{{ item.views }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</vant-list>
	</div>
</template>

<script>
import WTab from '@/components/Tab';
import keepMiXin from '@/mixins/keep';
import Transfer from '@/api/transfer';
import VantList from '@/components/VantList';

const keep = keepMiXin(['TransferDetails']);

export default {
	mixins: [keep],
	name: 'Transfer',
	data() {
		return {
			active: 0,
			loveData: [
				{
					title: '爱心传递'
				}
			],
			transferList: [],
			loading: true,
			finished: false,
			isEmpty: false,
			paramsPage: 1,
			paramsLimit: 10
		};
	},
	created() {
		this.onLoad();
	},
	methods: {
		onLoad() {
			this.loading = true;

			Transfer.donateList({
				page: this.paramsPage,
				limit: this.paramsLimit
			}).then(result => {

				this.transferList = this.transferList.concat(result.data.list);

				if (this.transferList.length <= 0) {
					this.isEmpty = true;
				}

				if (this.transferList.length >= result.data.total) {
					this.finished = true;
				}

				this.paramsPage++;
				this.loading = false;

			}).catch(error => {
				console.log(error);
			});
		},
		progressWidth(current, total) {
			let progress = parseInt((current / total) * 100);
			return progress < 100 ? progress : 100;
		},
		onTab(index) {
			this.active = index;
		}
	},
	components: {
		WTab,
		VantList
	}
};
</script>

<style lang="less" scoped>
.transfer {

	.public {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.head-top {
		width: 100%;
		height: 200px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.nav {
		background: #f2f2f2;
		border-radius: 15px 15px 0px 0px;
		padding: 13px 10px 2px 10px;
		margin-top: -13px;
		position: relative;
		z-index: 2;
	}

	.essay-list {
		margin: 10px 10px 0 10px;
		padding: 15px 10px;
		background: #fff;
		border-radius: 8px;

		.title {
			font-size: 18px;
			line-height: 24px;
			color: #222;
		}

		.text {
			font-size: 15px;
			line-height: 20px;
			color: #777;
			margin: 8px 0 14px 0;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}

		.middle {
			.p-line {
				position: relative;
				z-index: 1;
				width: 100%;
				height: 6px;
				border-radius: 2px;
				background: #ddd;
			}

			.p-done {
				position: absolute;
				z-index: 2;
				height: 6px;
				border-radius: 4px;
				background: #ffb23f;
				background-size: 30px 30px;
			}
		}

		.essay-list-sort {
			font-size: 12px;
			line-height: 22px;
			color: #777;
			margin-top: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.essay-list-amount {
			font-weight: 400;
			font-size: 18px;
			line-height: 22px;
			color: #222;
			font-weight: bold;
			margin-bottom: 19px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.footer {
			display: flex;
			justify-content: space-between;
			align-items: center;

			span {
				font-size: 13px;
				line-height: 13px;
				color: #777;
				margin-left: 5px;
			}

			.footer-left {
				font-size: 13px;
				line-height: 14px;
				color: #3377ff;
			}

			.footer-right {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.footer-right-keep {
					.public();
				}

				.footer-right-like {
					margin-left: 15px;
					.public();
				}
			}

			.picture {
				width: 16px;
				height: 16px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}
</style>
